<template>
<div>
    <div class="p_title" v-if="inspectButton('mem:lim:add')" >
        <!-- 创建直播 -->
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handle_liveSet('add',{})">{{$t('Createlivebroadcast')}}</el-button>
    </div>

    <div class="line"></div>
    <div class="tableBlock">
        <el-table 
            :data="tableData"
            style="width: 100%;margin-top:24px" 
            :height="tableHeight"
            v-tableHeight="{ bottomOffset: 74 }"
            :tableLoading="loading"
        >
            <!-- 频道信息 -->
            <el-table-column  :label="$t('Channel information')" prop="channel_name"></el-table-column>
            <!-- 类型 -->
            <el-table-column :label="$t('Types')" >
                <span >{{$t('Livebroadcast')}}</span>
            </el-table-column>
            <!-- 主持人 -->
            <el-table-column :label="$t('Moderator')" >
                <template slot-scope="scope">
                    <span>{{scope.row.host_name|textFormat}}</span>
                </template>
            </el-table-column>
            <!-- 操作时间 -->
            <el-table-column :label="$t('handleTime')" >
                <template slot-scope="scope">
                    <span>{{scope.row.updated_time|secondFormat('LLL')  }}</span>
                </template>    
            </el-table-column>
            <!-- <el-table-column prop="apply_type" label="坐席类型">
                <template slot-scope="scope">
                     1线上参展、2线下参展、3付费观众（线下需审核）、4 免费观众（线上免审核）
                    <span>{{scope.row.apply_type==1?"线上参展":scope.row.apply_type==2?"线下参展":scope.row.apply_type==3?"付费观众（线下需审核）":"免费观众（线上免审核）"}}</span>
                </template>
            </el-table-column> -->
            <!-- 操作 -->
            <el-table-column :label="$t('Edit')" width="300">
                <template slot-scope="scope">
                    <!-- 设置 -->
                    <el-button @click="handle_liveSet('set',scope.row)" v-if="inspectButton('mem:lim:mdi')" type="text" size="small">{{$t('Setup')}}</el-button>
                    <el-divider direction="vertical" v-if="inspectButton('mem:lim:mdi')"></el-divider>
                    <!-- 直播链接 -->
                    <el-button @click="handleLink(scope.row)" type="text" size="small">{{$t('Linkoflivebroadcast')}}</el-button>
                    <el-divider direction="vertical"></el-divider>
                    <!-- 主持人/嘉宾设置 -->
                    <el-button @click="handleGuest(scope.row)" type="text" size="small">{{$t('HostGuestsettings')}}</el-button>
                    <el-divider direction="vertical"></el-divider>
                    <!-- 删除 -->
                    <el-button type="text" size="small"  @click="handleDel(scope.row)">{{$t('delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="paginationBlock">
        <el-pagination
          @current-change="handleChange"
          :page-size="5"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
    </div>
    <!-- 直播链接的弹框 -->
    <el-dialog :title="$t('Linkoflivebroadcast')" :visible.sync="dialog_link" width="555px" >
        <div class="">
            <el-tabs v-model="activeName" >
                <!-- 主持人 -->
                <el-tab-pane :label="$t('Moderator')" name="1">
                    <div class="contentBox " v-if="link_detail.host.length!=0">
                        <div>
                            <span class="lable">{{$t('Startlink')}}：</span>
                            <p class="input_p textOverflow">{{link_detail.host[0].watch_url}}</p>
                            <div>
                                <el-button  @click="handle_open(link_detail.host[0].watch_url)" type="text"  size="small">{{$t('Open')}}</el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button @click="handle_liveCopy(link_detail.host[0])" type="text" size="small">{{$t('meetingCopy')}}</el-button>
                            </div>
                        </div>
                        <div class="type1">
                            <span>{{$t('Channelnumber')}}：{{link_detail.host[0].account|textFormat}}</span>
                            <span>{{$t('Log_in_password')}}：{{link_detail.host[0].passwd|textFormat}}</span>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 嘉宾 -->
                <el-tab-pane :label="$t('meetingUserAll')" name="2">
                    <div v-if="link_detail.host.jb!=0" class="dialog_link_box">
                        <div class="contentBox_2" v-for="(item,index) in link_detail.jb" :key="index">
                            <div class="j_name">{{ item.nickname|textFormat }}</div>
                            <div>
                                <span>{{index+1}}</span>
                                <p class="input_p textOverflow">{{item.watch_url}}</p>
                                <div>
                                    <el-button @click="handle_liveCopy(item)" type="text" size="small">{{$t('Copylinkandloginpassword')}}</el-button>
                                </div>
                            </div>
                            <div>
                                <span>{{$t('Log_in_password')}}：{{item.passwd|textFormat}}</span>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 观众 -->
                <el-tab-pane :label="$t('Audience')" name="3">
                    <div class="contentBox " v-if="link_detail.gz.length!=0">
                        <div>
                            <span class="lable">{{$t('Startlink')}}：</span>
                            <p class="input_p textOverflow">{{link_detail.gz[0].watch_url}}</p>
                            <div>
                                <el-button  @click="handle_open(link_detail.gz[0].watch_url)" type="text"  size="small">{{$t('Open')}}</el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button @click="handle_liveCopy(link_detail.gz[0])" type="text" size="small">{{$t('meetingCopy')}}</el-button>
                            </div>
                        </div>
                        <div class="type3">
                            <span class="lable">{{$t('ViewingH5')}}：</span>
                            <div id="idCode" style="margin: 10px 20px"></div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialog_link = false">{{$t('cancel')}}</el-button>
            <el-button size="small" type="primary" @click="dialog_link = false">{{$t('Confirm')}}</el-button>
        </div>
    </el-dialog>

</div>
    
</template>
<script>
import { priorFormat } from "~/basePlugins/filters";
export default {
    name:"conference-attendance",
    components:{

    },
    data(){
        return{
            activeName:"1",
            title:"",
            loading:false,
            total:20,
            currentPage:1,
            tableHeight: 100,
            defaultImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/head.png',
            tableData: [],
            dialog_link:false,
            data_item:{},
            link_detail:{
                host:[],
                jb:[],
                gz:[]
            },
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        handleChange(e) {
            this.currentPage = e;
            this.getList();
        },
        async getList(){
            this.loading=true
            let params={
                // meeting_id:this.$store.state.meeting_id,
                company_id:this.$store.state.baseStore.userInfo.company_id,
                limit:5,
                start:this.currentPage-1
            }
            let result = await this.$store.dispatch("baseConsole/getBoothLiveList",params);
            this.loading=false
            if(result && result.success){
                this.tableData=result.data
                this.total=result.total
            }
        },
        //删除
        async handleDel(row){
                //
                console.log(this.$store.state.meeting_id)
                console.log(row);
            let paramsLink={
                live_id:row.id,
                meeting_id:this.$store.state.meeting_id,
            }
            
            let live = await this.$store.dispatch("baseConsole/getLiveLink",paramsLink)
            console.log('live---',live);
            if(live && live.success){
                let params={
                    id:row.id
                }
                let del = await this.$store.dispatch("baseConsole/getBoothLiveDelete",params);
                if(del && del.success){
                    this.$message.success(this.$t('delSuccess'))
                    this.getList()
                }
            }else{
                this.$message.warning(this.$t('first_del_yicheng'))
            }
            
        },
        //直播链接
        async handleLink(row){
            let res = await this.$store.dispatch("baseConsole/getLIveList",{live_id:row.id});
            if(res && res.success){
                this.link_detail={
                    host:[],
                    jb:[],
                    gz:[]
                }
                for(var item of res.guest_list_data){
                    if(item.type==1){   // 主持人
                        this.link_detail.host.push(item)
                    }
                    if(item.type==2){   //嘉宾
                        this.link_detail.jb.push(item)
                    }
                    if(item.type==3){   //观众
                        this.link_detail.gz.push(item)
                    }
                }
                this.dialog_link=true
                let url=this.link_detail.gz[0].watch_url
                this.$nextTick(() => {
                    if (!this.qrcode) {
                    this.qrcode = new QRCode("idCode", {
                        text: url,
                        width: 106,
                        height: 106,
                        colorDark: "#000000",
                        colorLight: "#ffffff",
                        correctLevel: QRCode.CorrectLevel.H,
                    });
                    } else {
                    this.qrcode.clear();
                    this.qrcode.makeCode(url);
                    }
                });
            }
        },
        //设置&创建直播
        handle_liveSet(type,row){
            if(type=='set'){
                localStorage.liveParams=JSON.stringify(row)
            }
            this.$router.push({
                path:"/console/conference/live/live_set",
                query: {
                    type 
                }
            })
        },
        // 主持人设置
        handleGuest(row){
            let id=row.id
            this.$router.push({
                path:"/console/conference/live/guest",
                query: {
                    id,
                    start_time:row.start_time,
                    channel_id:row.channel_id
                }
            })
        },
        //直播链接跳转
        handle_open(url){
            window.open(url,"","",false)
        },
        //
        handle_liveCopy(row){
            const input = document.createElement("textarea");
            document.body.appendChild(input);
            if(this.activeName==1){// 主持人
                if(this.LOCALE=='zh'){
                    input.value = `${row.nickname?row.nickname:this.$t('Moderator')}，您好，${this.$t('dianjidenglu')}\n${this.$t('Loginpassword')}：${row.passwd}\n${this.$t('loginlink')}：${row.watch_url}`
                }else{
                    input.value = `Hello ${row.nickname?row.nickname:this.$t('Moderator')}，${this.$t('dianjidenglu')}\n${this.$t('Loginpassword')}：${row.passwd}\n${this.$t('loginlink')}：${row.watch_url}`
                }
            }
            if(this.activeName==2){
                let str=""
                if(this.LOCALE=='zh'){
                    str=`${row.nickname?row.nickname:"嘉宾"}，您好`
                }else{
                    str=`Hello ${row.nickname?row.nickname:"Guest"}，`
                }
                input.value = `${str}，${this.$t('dianjidenglu')}\n${this.$t('Loginpassword')}：${row.passwd}\n${this.$t('loginlink')}：${row.watch_url}`;
            }
            if(this.activeName==3){
                let str=""
                if(this.LOCALE=='zh'){
                    str=`${row.nickname?row.nickname:"观众"}，您好，`
                }else{
                    str=`Hello ${row.nickname?row.nickname:"Audience"}，`
                }
                input.value = `${str}\n${this.$t('Audiencelink')}：${row.watch_url}`;
            }
            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
            }
            document.body.removeChild(input);
            this.$message.success(this.$t('copySuccess'));
        },

    }
}
</script>
<style scoped lang="less">
    /deep/ .el-dialog__footer{
        border-top: 1px solid #F2F2F3;
    }
    /deep/ .dialog-footer{
        margin-top: 0;
        
    }
    .line{
        width: 100%;
        height: 1px;
        background: #C0C4CC;
    }
    .contentBox,.contentBox_2{
        .lable{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #415058;
            line-height: 20px;
        }
        .input_p{
            width: 322px;
            height: 35px;
            line-height: 35px;
            padding:0 5px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
        }

    }
    .contentBox>div:first-child{
        display: flex;
        align-items: center;
        p{
            width: 300px;
            border: 1px solid #333;
            margin-right: 20px;
        }
    }
    .contentBox>.type1{
        margin-top: 16px;
        padding-left:72px;
        display: flex;
        span{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #415058;
            line-height: 20px;
        }
        span:first-child{
            padding-right: 20px;
        }
    }
    .contentBox>.type3{
        margin-top: 16px;
        display: flex;
        span{
            padding-top: 10px;
        }
        img{
            width: 120px;
            height: 120px;
        }
    }
    .contentBox_2{
        margin-bottom:20px;
        .j_name{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #415058;
            line-height: 20px;
            margin-bottom:16px;
        }
    }
    .contentBox_2>div:nth-child(2){
        display: flex;
        align-items: center;
        span{
            padding-right:16px;
        }
        div{
            padding-left:16px;
        }
    }
    .contentBox_2>div:nth-child(3){
        padding-top:16px;
        margin-bottom: 30px;
    }
    .dialog_link_box{
        height:400px;
        overflow: scroll;
    }
    
    .tit{
        display: flex;
        align-items: center;
        img{
            width: 37px;
            height: 37px;
        }
        div{
            padding-left:8px;
        }
        p{
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 800;
            color: rgba(0, 0, 0, 0.85);
        }
        span{
            height: 17px;
            line-height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #909399;
        }
    }
    .states_box>div{
        display: flex ;
        align-items: center;
        div{
            width:6px;height:6px;
            background:#BFBFBF;
            border-radius:3px;
            display: inline-block;
            margin-right: 8px;
        }
        span{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #415058;
            line-height: 20px;
        }
    }
    .p_title{
        display: flex;
        align-items: center;
        padding:0 20px;
        height: 60px;
    }
</style>